import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=07049045&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BFormSelect, BFormGroup, BCol, BRow, BButton, BModal, BCard, BTable, BForm, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BFormSelect, BFormGroup, BCol, BRow, BButton, BModal, BCard, BTable, BForm, BContainer})
    


    import installDirectives from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports
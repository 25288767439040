import {getCart} from '@/services/CartService'
import { setMaxAmountForEachItem } from './setMaxAmountForEachItem'
import {stripAdministrationItemFromCartItems} from './stripAdministrationItemFromCartItems'

export  async function retrieveCartItems() {
    let items = await getCart()

    items = setMaxAmountForEachItem({items})
    items = stripAdministrationItemFromCartItems({cartItems: items})

    return items
}

